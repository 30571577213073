import {PlayerGroupEvent} from "../../../../store/PlayerGroup/PlayerGroup.slice";

const eventDateYear = 2024

export const getGroupEventsInDatabase = (): PlayerGroupEvent[] => {
  return [
    {
      date: new Date((eventDateYear - 1), 11, 2, 8, 15, 34).toString(),
      playerPseudo: 'OrlanduMike',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 0, 15, 17, 10, 46).toString(),
      playerPseudo: 'Legolas',
      message: "Interaction saisie avec Arwen : Pratique de tir à l'arc",
    },
    {
      date: new Date(eventDateYear, 1, 4, 7, 46, 13).toString(),
      playerPseudo: 'Arwen',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 2, 25, 13, 55, 42).toString(),
      playerPseudo: 'Gandalf',
      message: "Don de 4 Gems Vocation à Arwen",
    },
    {
      date: new Date(eventDateYear, 3, 15, 10, 24, 12).toString(),
      playerPseudo: 'Legolas',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 4, 5, 15, 45, 31).toString(),
      playerPseudo: 'Gandalf',
      message: "Objectif hebdomadaire rempli",
    },
    {
      date: new Date(eventDateYear, 5, 16, 9, 33, 45).toString(),
      playerPseudo: 'Legolas',
      message: "Félicitation envoyées à OrlanduMike pour le tournois du 30 avril",
    },
    {
      date: new Date(eventDateYear, 6, 7, 21, 43, 27).toString(),
      playerPseudo: 'OrlanduMike',
      message: "Gain du niveau 33",
    },
  ]
}
