import * as React from "react"

import * as ReactBootstrap from "react-bootstrap"

import {useAppDispatch} from "../../../store/hooks"
import {CoachPlayer} from "../../../store/coach/player-list/CoachPlayerList.slice"
import {
  setCoachPlayerProfileModalPlayer,
} from "../../../store/coach/player-profile-modal/CoachPlayerProfileModal.slice"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
  faCaretRight,
  faGears,
  faGifts,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"

import {
  goToHome,
} from "../../../store/main/main.slice"

import CoachPlayerList from "../../AppComponent/Coach/PlayerList/CoachPlayerList"
import CoachRecommendationList from "../../AppComponent/Coach/RecommendationList/CoachRecommendationList"
import PlayerProfileModal from "../../AppComponent/Coach/PlayerProfileModal/PlayerProfileModal"

import "./CoachConsole.scss"



const CoachConsole = () => {
  const dispatch = useAppDispatch()

  const goBackAction = () => {
    dispatch(goToHome())
  }

  const displayPlayerProfileModal = (coachPlayer: CoachPlayer|null) => {
    dispatch(setCoachPlayerProfileModalPlayer(coachPlayer))
  }

  return (
    <div className={'main-content coach-console'}>
      <div className={'main-page-content-container'}>
        <ReactBootstrap.Tab.Container
          id="coachPlayerList"
          defaultActiveKey="players"
        >
          <ReactBootstrap.Row
            className={'g-0 coach-console-container'}
          >

            <ReactBootstrap.Col
              md={3}
              lg={3}
              xl={3}
              xxl={2}
            >
              <div className={'coach-console-left-column'}>
                <div className={'coach-console-left-column-top'}>
                  <a
                    href={'#'}
                    className={'main-app-coach-logo'}
                    onClick={goBackAction}
                    title={"Retour"}
                  >
                    <img className={'main-logo'}
                         src={'/logo.png'}
                         alt={'Logo'}
                    />
                  </a>

                  <ReactBootstrap.Nav
                    variant="pills"
                    className="flex-column coach-console-menu"
                  >
                    <ReactBootstrap.Nav.Item className={'coach-console-menu-item'}>
                      <ReactBootstrap.Nav.Link
                        className={'coach-console-menu-item-link'}
                        eventKey="players"
                      >
                        <FontAwesomeIcon
                          className={'coach-console-menu-item-icon'}
                          icon={faUsers}
                        />
                        <span className={'coach-console-menu-item-label'}>Joueurs</span>
                      </ReactBootstrap.Nav.Link>
                    </ReactBootstrap.Nav.Item>

                    <ReactBootstrap.Nav.Item className={'coach-console-menu-item'}>
                      <ReactBootstrap.Nav.Link
                        className={'coach-console-menu-item-link'}
                        eventKey="recommendations"
                      >
                        <FontAwesomeIcon
                          className={'coach-console-menu-item-icon'}
                          icon={faCaretRight}
                        />
                        <span className={'coach-console-menu-item-label'}>Recommandations</span>
                      </ReactBootstrap.Nav.Link>
                    </ReactBootstrap.Nav.Item>

                    <ReactBootstrap.Nav.Item className={'coach-console-menu-item'}>
                      <ReactBootstrap.Nav.Link
                        className={'coach-console-menu-item-link'}
                        eventKey="awards"
                      >
                        <FontAwesomeIcon
                          className={'coach-console-menu-item-icon'}
                          icon={faGifts}
                        />
                        <span className={'coach-console-menu-item-label'}>Récompenses</span>
                      </ReactBootstrap.Nav.Link>
                    </ReactBootstrap.Nav.Item>

                    <ReactBootstrap.Nav.Item className={'coach-console-menu-item'}>
                      <ReactBootstrap.Nav.Link
                        className={'coach-console-menu-item-link'}
                        eventKey="configurations"
                      >
                        <FontAwesomeIcon
                          className={'coach-console-menu-item-icon'}
                          icon={faGears}
                        />
                        <span className={'coach-console-menu-item-label'}>Configurations</span>
                      </ReactBootstrap.Nav.Link>
                    </ReactBootstrap.Nav.Item>
                  </ReactBootstrap.Nav>
                </div>

                <div className={'coach-console-left-column-bottom'}>
                  <div className={'coach-console-coach-user'}>
                    <div className={'coach-console-coach-user-avatar'}>
                      <img className={'user-logo'}
                           src={'/user/coach.png'}
                           alt={'Logo'}
                      />
                    </div>
                    <div className={'coach-console-coach-user-name'}>
                      Micaël M.
                    </div>
                  </div>
                </div>
              </div>
            </ReactBootstrap.Col>

            <ReactBootstrap.Col
              md={9}
              lg={9}
              xl={9}
              xxl={10}
            >
              <ReactBootstrap.Tab.Content
                className={'coach-console-right-column'}
              >

                <ReactBootstrap.Tab.Pane
                  // className={'coach-console-tab-content'}
                  eventKey="players"
                >
                  <CoachPlayerList
                    displayPlayerProfileModal={displayPlayerProfileModal}
                  />
                </ReactBootstrap.Tab.Pane>

                <ReactBootstrap.Tab.Pane
                  className={'coach-console-tab-content'}
                  eventKey="recommendations"
                >
                  <div className={'coach-console-tab-content-header'}>
                    <div className={'coach-console-tab-content-header-left'}>
                      <div className={'coach-console-tab-content-title-container'}>
                        <h2 className={'coach-console-tab-content-title'}>Recommandations</h2>
                      </div>
                    </div>
                  </div>
                  <CoachRecommendationList/>
                </ReactBootstrap.Tab.Pane>

                <ReactBootstrap.Tab.Pane
                  eventKey="awards"
                >
                  En construction
                </ReactBootstrap.Tab.Pane>

                <ReactBootstrap.Tab.Pane
                  eventKey="configurations"
                >
                  En construction
                </ReactBootstrap.Tab.Pane>

              </ReactBootstrap.Tab.Content>
            </ReactBootstrap.Col>

          </ReactBootstrap.Row>
        </ReactBootstrap.Tab.Container>
        <PlayerProfileModal />

      </div>
    </div>
  )
}

export default CoachConsole
