import {useEffect} from "react";

import * as ReactBootstrap from "react-bootstrap";

import {useAppDispatch} from "../../../store/hooks";

import Card from "../../BaseComponent/CardComponent/Card/Card";
import Button from "../../BaseComponent/ButtonComponent/Button/Button";
import PageHeader from "../../PageComponent/LayoutComponent/PageHeader/PageHeader";
import NotificationList from "../../PageComponent/MessageComponent/NotificationList/NotificationList";

import {
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import {
    goToHome,
    goToAdventurerGroup,
    goToCoachConsole,
} from "../../../store/main/main.slice";
import {
    setNotificationDisplayState,
    setNotificationForceDisplayState,
} from "../../../store/NotificationList/NotificationList.slice";

import "./Profile.scss";

const Profile = () => {
    const dispatch = useAppDispatch();

    const goBackAction = () => {
        dispatch(setNotificationForceDisplayState(null));
        dispatch(setNotificationDisplayState('hidden'));
        dispatch(goToHome());
    };

    const goToAdventurerGroupAction = () => {
        dispatch(setNotificationForceDisplayState(null));
        dispatch(setNotificationDisplayState('hidden'));
        dispatch(goToAdventurerGroup());
    };

    const goToCoachConsoleAction = () => {
        dispatch(setNotificationForceDisplayState(null));
        dispatch(setNotificationDisplayState('hidden'));
        dispatch(goToCoachConsole());
    };

    useEffect(() => {
        dispatch(setNotificationForceDisplayState('displayed'));
    }, []);

    return (
        <div className={'main-content profile'}>
            <div className={'main-page-content-container'}>
                <PageHeader label={"Profil"} backLabel={"Retour"} backOnclick={goBackAction} />
                <div className={'page-content'}>
                    <div className={'row w-100'}>
                        <div className={'col-12 col-md-6'}>
                            <Card
                                className={'card-transparent'}
                                dataAos={'fade-right'}
                            >
                                <ReactBootstrap.Card.Header
                                    className={''}
                                >
                                    {"Actions"}
                                </ReactBootstrap.Card.Header>
                                <ReactBootstrap.Card.Body>
                                    <ReactBootstrap.Row className={'mb-3'}>
                                        <ReactBootstrap.Col>
                                            <Button
                                              variant={'primary'}
                                              size={'lg'}
                                              className={'btn-rounded'}
                                              gradiant={true}
                                              onClick={goToAdventurerGroupAction}
                                              icon={faChevronRight}
                                            >{"Groupe d'aventuriers"}</Button>
                                        </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                    <ReactBootstrap.Row>
                                        <ReactBootstrap.Col>
                                            <Button
                                              variant={'primary'}
                                              size={'lg'}
                                              className={'btn-rounded'}
                                              gradiant={true}
                                              onClick={goToCoachConsoleAction}
                                              icon={faChevronRight}
                                            >{"Console accompagnant"}</Button>
                                        </ReactBootstrap.Col>
                                    </ReactBootstrap.Row>
                                </ReactBootstrap.Card.Body>
                            </Card>
                        </div>
                        <div className={'col-12 col-md-6'}>
                            <Card
                                className={'card-transparent'}
                                dataAos={'fade-left'}
                            >
                                <ReactBootstrap.Card.Header
                                    className={''}
                                >
                                    {"Notifications"}
                                </ReactBootstrap.Card.Header>
                                <ReactBootstrap.Card.Body>
                                    <NotificationList/>
                                </ReactBootstrap.Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
