import * as React from "react";
import {MouseEventHandler} from "react";

import * as ReactBootstrap from "react-bootstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCrown,
} from "@fortawesome/free-solid-svg-icons";

import Card from "../../../BaseComponent/CardComponent/Card/Card";
import PlayerAvatar from "../PlayerAvatar/PlayerAvatar";

import {Player} from "../../../../store/PlayerGroup/PlayerGroup.slice";

import './PlayerCard.scss'



interface PlayerCardProps {
    player: Player;
    isActivePlayer: boolean;
    dataAos?: string;
    onClick?: MouseEventHandler;
}

const PlayerCard = ({player, isActivePlayer, dataAos, onClick}: PlayerCardProps) => {
    return (
        <Card
            className={'card-transparent player-card' + (player.isLoggedUser ? ' player-card-avatar-is-logged-user' : '') + (isActivePlayer ? ' active' : '')}
            dataAos={dataAos}
            onClick={onClick}
        >
            <ReactBootstrap.Card.Body>
                <div className={'player-card-avatar'}>
                    <PlayerAvatar
                      player={player}
                    />
                </div>
                <div className={'player-card-pseudo'}>{player.pseudo}</div>
            </ReactBootstrap.Card.Body>
        </Card>
    )
}

export default PlayerCard;
