import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import ProgressBar from "../../../BaseComponent/ProgressBarComponent/ProgressBar/ProgressBar";
import ProgressBarCircular from "../../../BaseComponent/ProgressBarComponent/ProgressBarCircular/ProgressBarCircular";

import {
  getDiamonds,
  getGoldCoins,
  // getPotions,
  // getExperience,
  goToProfile,
} from "../../../../store/main/main.slice"
import {
  getIsUserInitialized,
} from "../../../../store/user/user.slice"
import {
  closeUserCoachMessage,
} from "../../../../store/user/user-coach/user-coach.slice"

import "./Header.scss"

const Main = () => {
  const dispatch = useAppDispatch();

  const profile = () => {
    dispatch(closeUserCoachMessage())
    dispatch(goToProfile())
  };

  const isUserInitialized = useAppSelector(getIsUserInitialized)

  const diamonds = useAppSelector(getDiamonds)
  const goldCoins = useAppSelector(getGoldCoins)
  // const potions = useAppSelector(getPotions);
  // const experience = useAppSelector(getExperience);

  return (
    <div
      className={'main-header'}
    >
      {!isUserInitialized && <div>Empowered Game</div>}
      {isUserInitialized && <div className={'main-header-container'}>
        <div
          className={'first-container'}
          data-aos="fade-right"
          data-aos-offset="0"
        >
          <div className={'main-logo-container'}>
            <img className={'main-logo'}
                 src={'/logo.png'}
                 alt={'Logo'}
            />
          </div>
        </div>

        <div className={'second-container'}>
          <div
            className={'energy-bar-container'}
            data-aos="fade-right"
            data-aos-offset="0"
          >
            <div className={'energy-bar-label'}>Barre d'énergie</div>
            <ProgressBar
              percentage={60}
              additionalClassName={''}
            />
          </div>
          <div
            className={'items-container'}
            data-aos="fade-left"
            data-aos-offset="0"
          >
            <div className={'items'}>
              <div className={'item'}>
                <img className={'item-image'}
                     src={'/object/diamond.png'}
                     alt={'Diamond'}
                />
                <div className={'item-label'}>{diamonds}</div>
              </div>
              <div className={'item'}>
                <img className={'item-image'}
                     src={'/object/gold_coin.png'}
                     alt={'Gold coin'}
                />
                <div className={'item-label'}>{goldCoins}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={'third-container'}
          // data-aos="fade-left"
        >
          <ProgressBarCircular
            percentage={60}
            label={'Profil'}
            labelOnHover={true}
            image={'/user/avatar.png'}
            imageAlt={'User avatar'}
            onclick={profile}
          />
        </div>
        {/*<div className={'item'}>*/}
        {/*    <img className={'item-image'} src={'/object/potion.png'} alt={'Potion'}/>*/}
        {/*    <div className={'item-label'}>{ potions }</div>*/}
        {/*</div>*/}
        {/*<div className={'item'}>*/}
        {/*    <img className={'item-image'} src={'/object/experience.png'} alt={'Experience'}/>*/}
        {/*    <div className={'item-label'}>{ experience }</div>*/}
        {/*</div>*/}
      </div>}
    </div>
  );
};

export default Main;
