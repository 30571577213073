import {CoachPlayer} from "../../../store/coach/player-list/CoachPlayerList.slice";
import {
  faBolt,
  faCalendarDay,
  faCaretRight,
  faClipboard,
  faHeart,
  faTrophy,
  faUser
} from "@fortawesome/free-solid-svg-icons";

export const getCoachPlayersInDatabase = (): CoachPlayer[] => {
  return [
    {
      id: 1,
      fullName: 'David Laroche',
      progress: {},
      successfulWeeks: 3,
      actions: [
        {
          code: 'sendMessage',
          label: 'Envoyer Message',
          icon: faCaretRight,
          isRecommendation: false,
        },
        {
          code: 'changeMeeting',
          label: 'Accompagnement',
          icon: faCalendarDay,
          isRecommendation: false,
        },
        {
          code: 'boost',
          label: 'Booster',
          icon: faBolt,
          isRecommendation: false,
        },
        {
          code: 'encourage',
          label: 'Encourager',
          icon: faHeart,
          isRecommendation: true,
        },
        {
          code: 'congratulate',
          label: 'Féliciter',
          icon: faTrophy,
          isRecommendation: false,
        },
        {
          code: 'addNote',
          label: 'Ajouter Note',
          icon: faClipboard,
          isRecommendation: false,
        },
        {
          code: 'seeProfile',
          label: 'Profil',
          icon: faUser,
          isRecommendation: false,
        },
      ],
      player: {
        id: 1,
        pseudo: 'Sangoku',
        avatar: '/player/avatar/avatar_sangoku.jpg',
        level: 55,
        energy: 15,
        weeklyProgression: 1,
        group: {
          name: 'Saiyans Rensenki',
          avatar: '/player_group/avatar/avatar_saiyans_resenki.jpg',
        },
        isLeader: true,
        isLoggedUser: false,
        events: [],
        information: {
          playerClass: 'Guerrier',
          competence: 'Épées énergisées',
          objectives: [
            {
              categoryName: 'Vocation',
              description: "J'inspire mes collègue à donner le meilleur d'eux-mêmes et à se responsabiliser tout en révélant leur potentiel. Mon optimisme, ma motivation, ma persévérance et mon professionnalisme sont un modèle. Mon écoute me permet d'accompagner mes collègues dans les situations problématiques qu'ils rencontrent. Je m'améliore, je m'amuse, j'expérimente et je contribue à des initiatives impactantes, ce qui me donne de l'énergie et un sentiment d'accomplissement.",
            },
          ],
        },
      },
    },
    {
      id: 2,
      fullName: 'Tony Robbins',
      progress: {},
      successfulWeeks: 3,
      actions: [
        {
          code: 'sendMessage',
          label: 'Envoyer Message',
          icon: faCaretRight,
          isRecommendation: false,
        },
        {
          code: 'changeMeeting',
          label: 'Accompagnement',
          icon: faCalendarDay,
          isRecommendation: false,
        },
        {
          code: 'boost',
          label: 'Booster',
          icon: faBolt,
          isRecommendation: false,
        },
        {
          code: 'encourage',
          label: 'Encourager',
          icon: faHeart,
          isRecommendation: false,
        },
        {
          code: 'congratulate',
          label: 'Féliciter',
          icon: faTrophy,
          isRecommendation: false,
        },
        {
          code: 'addNote',
          label: 'Ajouter Note',
          icon: faClipboard,
          isRecommendation: false,
        },
        {
          code: 'seeProfile',
          label: 'Profil',
          icon: faUser,
          isRecommendation: false,
        },
      ],
      player: {
        id: 2,
        pseudo: 'Aragorn',
        avatar: '/player/avatar/avatar_player.png',
        level: 34,
        energy: 64,
        weeklyProgression: 1,
        group: {
          name: "Communauté de l'anneau",
          avatar: '/player_group/avatar/avatar_ring_community.jpg',
        },
        isLeader: true,
        isLoggedUser: false,
        events: [],
        information: {
          playerClass: 'Druide',
          competence: 'Énergie céleste',
          objectives: [
            {
              categoryName: 'Couple',
              description: "J'aime mon partenaire de vie tout en ayant le temps de m'accomplir dans mes passions. Nous vivons dans le respect et la confiance avec une belle complicité nous permettant de communiquer aisément nos désirs et besoins. Nous prenons le temps nécessaire pour cultiver notre relation amoureuse en harmonie avec nos vocations respectives. Nous optimisons cette balance tout en réservant du temps pour se ressourcer.",
            },
          ],
        },
      },
    },
    {
      id: 3,
      fullName: 'Andrew Ferebee',
      progress: {},
      successfulWeeks: 2,
      actions: [
        {
          code: 'sendMessage',
          label: 'Envoyer Message',
          icon: faCaretRight,
          isRecommendation: false,
        },
        {
          code: 'changeMeeting',
          label: 'Accompagnement',
          icon: faCalendarDay,
          isRecommendation: false,
        },
        {
          code: 'boost',
          label: 'Booster',
          icon: faBolt,
          isRecommendation: true,
        },
        {
          code: 'encourage',
          label: 'Encourager',
          icon: faHeart,
          isRecommendation: false,
        },
        {
          code: 'congratulate',
          label: 'Féliciter',
          icon: faTrophy,
          isRecommendation: false,
        },
        {
          code: 'addNote',
          label: 'Ajouter Note',
          icon: faClipboard,
          isRecommendation: false,
        },
        {
          code: 'seeProfile',
          label: 'Profil',
          icon: faUser,
          isRecommendation: false,
        },
      ],
      player: {
        id: 3,
        pseudo: 'Batman',
        avatar: '/player/avatar/avatar_batman.jpg',
        level: 95,
        energy: 87,
        weeklyProgression: 1,
        group: {
          name: 'Héros de Gotham',
          avatar: '/player_group/avatar/avatar_heros_de_gotham.jpg',
        },
        isLeader: false,
        isLoggedUser: false,
        events: [],
        information: {
          playerClass: 'Guerrier',
          competence: 'Vents bénéfiques',
          objectives: [
            {
              categoryName: 'Finance',
              description: "Bénéficier d'une liberté financière me permettant d'entreprendre et soutenir les projets que je désire réaliser sans avoir à solliciter des fonds à d'autres personnes.",
            },
          ],
        },
      },
    },
    {
      id: 4,
      fullName: 'David Goggins',
      progress: {},
      successfulWeeks: 5,
      actions: [

        {
          code: 'sendMessage',
          label: 'Envoyer Message',
          icon: faCaretRight,
          isRecommendation: false,
        },
        {
          code: 'changeMeeting',
          label: 'Accompagnement',
          icon: faCalendarDay,
          isRecommendation: false,
        },
        {
          code: 'boost',
          label: 'Booster',
          icon: faBolt,
          isRecommendation: false,
        },
        {
          code: 'encourage',
          label: 'Encourager',
          icon: faHeart,
          isRecommendation: false,
        },
        {
          code: 'congratulate',
          label: 'Féliciter',
          icon: faTrophy,
          isRecommendation: true,
        },
        {
          code: 'addNote',
          label: 'Ajouter Note',
          icon: faClipboard,
          isRecommendation: false,
        },
        {
          code: 'seeProfile',
          label: 'Profil',
          icon: faUser,
          isRecommendation: false,
        },
      ],
      player: {
        id: 4,
        pseudo: 'Invincible',
        avatar: '/player/avatar/avatar_invincible.jpeg',
        level: 24,
        energy: 13,
        weeklyProgression: 1,
        group: {
          name: 'Coalition des planètes',
          avatar: '/player_group/avatar/avatar_coalition_des_planetes.jpg',
        },
        isLeader: true,
        isLoggedUser: false,
        events: [],
        information: {
          playerClass: 'Mage',
          competence: 'Boule de feu',
          objectives: [
            {
              categoryName: 'Spiritualité',
              description: "Mieux gérer mon nouveau niveau de conscience afin de lâcher prise sur ce qui est moins important tout en étant plus attentif aux messages qui me sont destinés.",
            },
          ],
        },
      },
    },
    {
      id: 5,
      fullName: 'David Le Francois',
      progress: {},
      successfulWeeks: 1,
      actions: [
        {
          code: 'sendMessage',
          label: 'Envoyer Message',
          icon: faCaretRight,
          isRecommendation: false,
        },
        {
          code: 'changeMeeting',
          label: 'Accompagnement',
          icon: faCalendarDay,
          isRecommendation: false,
        },
        {
          code: 'boost',
          label: 'Booster',
          icon: faBolt,
          isRecommendation: false,
        },
        {
          code: 'encourage',
          label: 'Encourager',
          icon: faHeart,
          isRecommendation: false,
        },
        {
          code: 'congratulate',
          label: 'Féliciter',
          icon: faTrophy,
          isRecommendation: false,
        },
        {
          code: 'addNote',
          label: 'Ajouter Note',
          icon: faClipboard,
          isRecommendation: false,
        },
        {
          code: 'seeProfile',
          label: 'Profil',
          icon: faUser,
          isRecommendation: false,
        },
      ],
      player: {
        id: 5,
        pseudo: 'Inspiring',
        avatar: '/player/avatar/avatar_inspiring.png',
        level: 47,
        energy: 95,
        weeklyProgression: 1,
        group: {
          name: "Communauté de l'anneau",
          avatar: '/player_group/avatar/avatar_ring_community.jpg',
        },
        isLeader: false,
        isLoggedUser: false,
        events: [],
        information: {
          playerClass: 'Mage',
          competence: 'Boule de feu',
          objectives: [
            {
              categoryName: 'Spiritualité',
              description: "Mieux gérer mon nouveau niveau de conscience afin de lâcher prise sur ce qui est moins important tout en étant plus attentif aux messages qui me sont destinés.",
            },
          ],
        },
      },
    },
  ]
}
