import {createSlice} from "@reduxjs/toolkit";

import {RootState} from "../../store";

import {CoachPlayer} from "../player-list/CoachPlayerList.slice";



interface CoachPlayerProfileModalState {
  player: CoachPlayer|null;
  isPlayerDetailsOpen: boolean;
}

const initialState: CoachPlayerProfileModalState = {
  player: null,
  isPlayerDetailsOpen: false,
};

export const CoachPlayerProfileModal = createSlice({
  name: "coach-player-profile-modal",
  initialState,
  reducers: {
    setCoachPlayerProfileModalPlayer: (state, action) => {
      state.player = action.payload;
    },
    setCoachPlayerProfileModalDetailsIsOpen: (state, action) => {
      state.isPlayerDetailsOpen = action.payload;
    },
  },
})

export const {
  setCoachPlayerProfileModalPlayer,
  setCoachPlayerProfileModalDetailsIsOpen,
} = CoachPlayerProfileModal.actions;

export const getCoachPlayerProfileModalPlayer = (state: RootState) => state.coachPlayerProfileModal.player;
export const getCoachPlayerProfileModalDetailsIsOpen = (state: RootState) => state.coachPlayerProfileModal.isPlayerDetailsOpen;

export default CoachPlayerProfileModal.reducer;
