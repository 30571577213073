import * as React from "react";

import * as ReactBootstrap from "react-bootstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons";

import {Player, PlayerEvent, PlayerEventAction} from "../../../../store/PlayerGroup/PlayerGroup.slice";

import {getTranslatedDate} from "../../../../utils/date-utils";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";
import PlayerAvatar from "../../../AppComponent/Player/PlayerAvatar/PlayerAvatar";

import './PlayerProfileDetails.scss'



interface PlayerProfileDetailsProps {
  player: Player;
  isLoggedPlayer: boolean;
  onClick?: (player: Player, playerEvent: PlayerEvent, playerEventAction: PlayerEventAction) => void;
}

const PlayerProfileDetails = ({player, isLoggedPlayer, onClick}: PlayerProfileDetailsProps) => {
  const handleActionClick = (player: Player, playerEvent: PlayerEvent, playerEventAction: PlayerEventAction) => {
    onClick && onClick(player, playerEvent, playerEventAction)
  }

  return (
    <div className={'player-group-team-player-info row g-2 mt-0 mb-2'}>
      <div className={'col-12 col-md-4 col-lg-2'}>
        <div className={'player-group-team-player-info-details'}>
          <div className={'player-group-team-player-info-detail-pseudo'}>
            {player.isLeader &&
              <FontAwesomeIcon
                icon={faCrown}
                fixedWidth
              />
            }
            <span>{player.pseudo}</span>
          </div>
          <div className={'player-group-team-player-info-detail-avatar mt-2'}>
            <PlayerAvatar
              player={player}
            />
          </div>
          <div className={'player-group-team-player-info-detail-level'}>
            <span className={'player-group-team-player-info-label'}>Niveau : </span>
            <span>{player.level}</span>
          </div>
          <div className={'player-group-team-player-info-detail-energy'}>
            <span className={'player-group-team-player-info-label'}>Énergie : </span>
            <span>{player.energy}%</span>
          </div>
          <div className={'player-group-team-player-info-detail-weekly-progression'}>
            <span className={'player-group-team-player-info-label'}>Progression hebdomadaire : </span>
            <span>{player.weeklyProgression}%</span>
          </div>
        </div>
      </div>
      <div className={'col-12 col-md-8 col-lg-10'}>
        <div className={'player-group-team-player-info-actions-and-information'}>
          <ReactBootstrap.Tabs
            defaultActiveKey="player_actions"
            id="player-actions-and-information"
            className="player-group-team-player-info-actions-and-information-tabs"
          >
            <ReactBootstrap.Tab eventKey="player_actions"
                                title={'Actions' + ' (' + player.events.length + ')'}>
              <div className={'player-group-team-player-events'}>
                {player.events.length === 0 &&
                  <div className={'player-group-team-player-no-event'}>
                      Il n'y a actuellement aucune action pour cet aventurier.
                  </div>
                }
                {player.events.map((playerEvent, playerEventIndex) => {
                  return (
                    <div
                      key={playerEventIndex}
                      className={'player-group-team-player-event row g-0'}
                      data-aos={'fade-left'}
                      data-aos-easing={'linear'}
                      data-aos-duration={'500'}
                      data-aos-offset={'0'}
                    >
                      <div className={'col-12 col-md-2'}>
                        <div className={'player-group-team-player-event-date'}>
                          {getTranslatedDate(playerEvent.date)}
                        </div>
                      </div>
                      <div className={'col-12 col-md-6'}>
                        <div className={'player-group-team-player-event-label'}>
                          {playerEvent.label}
                        </div>
                      </div>
                      <div className={'col-12 col-md-4'}>
                        <div className={'player-group-team-player-event-actions'}>
                          {!isLoggedPlayer && playerEvent.actions.map((playerEventAction, playerEventActionIndex) => {
                            return (
                              <div
                                key={playerEventActionIndex}
                                className={'player-group-team-player-event-action'}
                              >
                                <Button
                                  variant={playerEventAction.type === 'ignore' ? 'secondary' : 'primary'}
                                  className={'btn-rounded'}
                                  gradiant={true}
                                  onClick={() => handleActionClick(player, playerEvent, playerEventAction)}
                                  dataAos={'fade-left'}
                                  dataAosOffset={'0'}
                                  dataAosDelay={'400'}
                                >{playerEventAction.label}</Button>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </ReactBootstrap.Tab>
            <ReactBootstrap.Tab eventKey="player_information" title="Details">
              {player.information &&
                <div className={'player-group-team-player-info-information'}>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6'}>
                            <div className={'player-group-team-player-info-information-player-class'}>
                                <span className={'player-group-team-player-info-label'}>Type : </span>
                                <span>{player.information.playerClass}</span>
                            </div>
                        </div>
                        <div className={'col-12 col-md-6'}>
                            <div className={'player-group-team-player-info-information-competence'}>
                                    <span
                                      className={'player-group-team-player-info-label'}>Compétence allouée : </span>
                                <span>{player.information.competence}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'player-group-team-player-info-information-objectives'}>
                        <div className={'player-group-team-player-info-information-objectives-label'}>
                            <span className={'player-group-team-player-info-label'}>Objectifs : </span>
                        </div>
                        <div className={'player-group-team-player-info-information-objective-list'}>
                          {player.information.objectives.map((playerObjective, playerObjectiveIndex) => {
                            return (
                              <div
                                key={playerObjectiveIndex}
                                className={'player-group-team-player-info-information-objective-list-item'}
                              >
                                <div
                                  className={'player-group-team-player-info-information-objective-list-item-category'}
                                >
                                  <span
                                    className={'player-group-team-player-info-label'}
                                  >{'Domaine de vie : '}</span>
                                  <span>{playerObjective.categoryName}</span>
                                </div>
                                <div
                                  className={'player-group-team-player-info-information-objective-list-item-description'}
                                >
                                  <span
                                    className={'player-group-team-player-info-label'}
                                  >{'Description : '}</span>
                                  <span>{playerObjective.description}</span>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                    </div>
                </div>
              }
            </ReactBootstrap.Tab>
          </ReactBootstrap.Tabs>
        </div>
      </div>
    </div>
  )
}

export default PlayerProfileDetails;
