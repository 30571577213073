import * as React from "react"
import {useEffect} from "react";
import * as ReactBootstrap from "react-bootstrap"

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faFireFlameCurved,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import {
  Player,
  PlayerEvent,
  PlayerEventAction,
  getPlayers,
  getSelectedPlayer,
  getEvents,
  setPlayersToState,
  setSelectedPlayerToState,
  setEventsToState,
  addEventToState,
  removePlayerEventToState,
} from "../../../../store/PlayerGroup/PlayerGroup.slice";

import {getTranslatedDate} from "../../../../utils/date-utils";
import {getGroupPlayersInDatabase} from "../../../../utils/fake-data/player/group/players";
import {getGroupEventsInDatabase} from "../../../../utils/fake-data/player/group/events";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";
import PlayerCard from "../PlayerCard/PlayerCard";
import PlayerProfileDetails from "../PlayerProfileDetails/PlayerProfileDetails";

import "./PlayerGroup.scss"



const PlayerGroup = () => {
  const dispatch = useAppDispatch()

  const playersInDb = getGroupPlayersInDatabase()
  const players = useAppSelector(getPlayers)
  const selectedPlayer = useAppSelector(getSelectedPlayer)

  const loggedPlayerInDb = playersInDb.find(playerInDb => playerInDb.isLoggedUser);
  const isSelectedPlayerLoggedPlayer = selectedPlayer?.id === loggedPlayerInDb?.id

  const eventsInDb = getGroupEventsInDatabase()
  const events = useAppSelector(getEvents)
  let reversedEvents = [...events]
  reversedEvents.reverse()

  const setSelectedPlayer = (player: Player | null) => {
    dispatch(setSelectedPlayerToState(player))
  }

  const addEventToGroupHistory = (player: Player, playerEvent: PlayerEvent, playerEventAction: PlayerEventAction) => {
    if (!loggedPlayerInDb) {
      return
    }

    if (playerEventAction.addPlayerGroupEvent) {
      dispatch(addEventToState({
        date: new Date().toString(),
        playerPseudo: loggedPlayerInDb.pseudo,
        message: playerEventAction.addPlayerGroupEvent.message,
      }))
    }

    dispatch(removePlayerEventToState({
      player: player,
      playerEvent: playerEvent
    }))
  }

  useEffect(() => {
    dispatch(setPlayersToState(playersInDb))
    const selectedPlayerFromDb = loggedPlayerInDb ? loggedPlayerInDb : null;
    setSelectedPlayer(selectedPlayerFromDb)

    dispatch(setEventsToState(eventsInDb))
  }, [])

  return (
    <div className={'player-group-container'}>
      <div className={'player-group-info'}>
        <div className={'player-group-info-image'}>
          <img className={'player-group-info-imag-img'}
               src={"/player_group/avatar/avatar_ring_community.jpg"}
               alt={'Adventurer group image'}
               width={100}
               height={100}
          />
        </div>
        <div className={'player-group-info-name'}>{"Communauté de l'anneau"}</div>
      </div>
      <div className={'player-group-bonuses-and-actions'}>
        <div className={'player-group-active-bonuses'}>
          <div className={'player-group-active-bonuses-label'}>
            Bonus actifs :
          </div>
          <div className={'player-group-active-bonuses-list'}>
            <ReactBootstrap.Badge bg={'success'}>
              +25% Expérience
            </ReactBootstrap.Badge>
            <ReactBootstrap.Badge bg={'danger'}>
              On Fire
              <FontAwesomeIcon
                icon={faFireFlameCurved}
                fixedWidth
              />
            </ReactBootstrap.Badge>
          </div>
        </div>
        <div className={'player-group-actions'}>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Lancer Défi"}</Button>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Jouer Ensemble"}</Button>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Communiquer"}</Button>
          <Button
            variant={'primary'}
            size={'lg'}
            className={'btn-rounded'}
            gradiant={true}
          >{"Saisir Intéraction"}</Button>
          {/*<ReactBootstrap.Dropdown>*/}
          {/*    <ReactBootstrap.Dropdown.Toggle*/}
          {/*        id="player-group-actions"*/}
          {/*        className={'btn-lg btn-rounded btn-gradiant'}*/}
          {/*        variant={'primary'}*/}
          {/*    >*/}
          {/*        Actions*/}
          {/*    </ReactBootstrap.Dropdown.Toggle>*/}
          {/*    <ReactBootstrap.Dropdown.Menu>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Lancer Défi</ReactBootstrap.Dropdown.Item>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Jouer Ensemble</ReactBootstrap.Dropdown.Item>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Communiquer</ReactBootstrap.Dropdown.Item>*/}
          {/*        <ReactBootstrap.Dropdown.Item href="#">Saisir Intéraction</ReactBootstrap.Dropdown.Item>*/}
          {/*    </ReactBootstrap.Dropdown.Menu>*/}
          {/*</ReactBootstrap.Dropdown>*/}
        </div>
      </div>
      <div className={'player-group-team'}>
        <div className={'player-group-team-players row g-3 mt-0 mb-3'}>
          {players.map((player, playerIndex) => {
            const isActivePlayer = player.id === selectedPlayer?.id
            return (
              <div
                key={playerIndex}
                className={'col-6 col-sm-4 col-md-3 col-lg-2 col-xxl-1'}
              >
                <PlayerCard
                  player={player}
                  isActivePlayer={isActivePlayer}
                  // dataAos={'fade-left'}
                  onClick={!isActivePlayer ? () => setSelectedPlayer(player) : undefined}
                />
              </div>
            )
          })}
          <div className={'col-6 col-sm-4 col-md-3 col-lg-2 col-xxl-2'}>
            <div className={'player-group-team-player-action'}>
              <Button
                variant={'secondary'}
                size={'lg'}
                className={'btn-rounded'}
                gradiant={true}
                icon={faUserPlus}
                dataAos="fade-left"
                dataAosOffset={'0'}
              >{"Inviter"}</Button>
            </div>
          </div>
        </div>
        {selectedPlayer &&
          <PlayerProfileDetails
            player={selectedPlayer}
            isLoggedPlayer={isSelectedPlayerLoggedPlayer}
            onClick={(player: Player, playerEvent: PlayerEvent, playerEventAction: PlayerEventAction) => addEventToGroupHistory(player, playerEvent, playerEventAction)}
          />
        }
      </div>

      <div className={'player-group-history-and-leave-action row g-2'}>
        <div className={'col-12 col-md-9'}>
          <div className={'player-group-history'}>
            <div className={'player-group-history-label'}>Historique du groupe</div>
            <div className={'player-group-history-items'}>
              {reversedEvents.length === 0 &&
                <div className={'player-group-history-no-item'}>
                    Il n'y a actuellement aucun historique.
                </div>
              }
              {reversedEvents.map((playerGroupEvent, playerGroupEventIndex) => {
                return (
                  <div
                    key={playerGroupEventIndex}
                    className={'player-group-history-item row g-0'}
                  >
                    <div className={'player-group-history-item-date col-12 col-md-3'}>
                      {getTranslatedDate(playerGroupEvent.date, false, 'except_current_year')}
                    </div>
                    <div className={'player-group-history-item-player-pseudo col-12 col-md-3'}>
                      {playerGroupEvent.playerPseudo}
                    </div>
                    <div className={'player-group-history-item-message col-12 col-md-6'}>
                      {playerGroupEvent.message}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={'col-12 col-md-3'}>
          <div className={'player-group-leave-action'}>
            <Button
              variant={'secondary'}
              size={'lg'}
              className={'btn-rounded'}
              gradiant={true}
              icon={faArrowRightFromBracket}
            >{"Quitter le groupe"}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayerGroup