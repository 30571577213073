import * as React from "react";

import {Player} from "../../../../store/PlayerGroup/PlayerGroup.slice";

import './PlayerAvatar.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/free-solid-svg-icons";
import * as ReactBootstrap from "react-bootstrap";

interface PlayerAvatarProps {
    player: Player;
    width?:number
    height?:number
}

const PlayerAvatar = ({player, width, height}: PlayerAvatarProps) => {
    width = width ? width : 100
    height = height ? height : 100

    return (
      <div className={'player-avatar'}>
          <div className={'player-avatar-container'}>
              <img
                className={'player-avatar-image'}
                src={player.avatar}
                alt={player.pseudo + ' avatar'}
                width={width}
                height={height}
              />
              {player.isLeader &&
                <div className={'player-avatar-leader'}>
                    <FontAwesomeIcon
                      icon={faCrown}
                      fixedWidth
                    />
                </div>
              }
              <div className={'player-avatar-level'}>
                  <ReactBootstrap.Badge pill bg="danger">{player.level}</ReactBootstrap.Badge>
              </div>
          </div>
      </div>
    )
}

export default PlayerAvatar;
