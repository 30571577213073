import {Player} from "../../../../store/PlayerGroup/PlayerGroup.slice";

const eventDateYear = 2024

export const getGroupPlayersInDatabase = (): Player[] => {
  return [
    {
      id: 1,
      pseudo: 'OrlanduMike',
      avatar: '/player/avatar/avatar_player.png',
      level: 33,
      energy: 80,
      weeklyProgression: 77,
      group: null,
      isLeader: true,
      isLoggedUser: true,
      events: [
        {
          id: 1,
          date: new Date(eventDateYear, 9, 24, 16, 0, 0).toString(),
          label: 'Gagner tournois + Prix objectif Magique',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Féliciter',
              addPlayerGroupEvent: {
                message: "Félicitation envoyées à OrlanduMike pour le tournois du 25 octobre",
              },
            },
          ],
        },
        {
          id: 2,
          date: new Date(eventDateYear, 9, 25, 16, 0, 0).toString(),
          label: 'Demande de soutien 2 Gems Santé',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Donner',
              addPlayerGroupEvent: {
                message: "Don de 2 Gems Santé à OrlanduMike",
              },
            },
          ],
        },
      ],
      information: {
        playerClass: 'Guerrier',
        competence: 'Épées énergisées',
        objectives: [
          {
            categoryName: 'Vocation',
            description: "J'inspire mes collègue à donner le meilleur d'eux-mêmes et à se responsabiliser tout en révélant leur potentiel. Mon optimisme, ma motivation, ma persévérance et mon professionnalisme sont un modèle. Mon écoute me permet d'accompagner mes collègues dans les situations problématiques qu'ils rencontrent. Je m'améliore, je m'amuse, j'expérimente et je contribue à des initiatives impactantes, ce qui me donne de l'énergie et un sentiment d'accomplissement.",
          },
        ],
      },
    },
    {
      id: 2,
      pseudo: 'Arwen',
      avatar: '/player/avatar/avatar_arwen.jpg',
      level: 70,
      energy: 45,
      weeklyProgression: 40,
      group: null,
      isLeader: false,
      isLoggedUser: false,
      events: [
        {
          id: 10,
          date: new Date(eventDateYear, 3, 3, 16, 0, 0).toString(),
          label: 'Gagner tournois + Prix objectif Santé',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Féliciter',
              addPlayerGroupEvent: {
                message: "Félicitation envoyées à Arwen pour le tournois du 3 mars",
              },
            },
          ],
        },
        {
          id: 11,
          date: new Date(eventDateYear, 3, 10, 16, 0, 0).toString(),
          label: 'Demande de soutien 4 Gems Magie',
          actions: [
            {
              type: 'ignore',
              label: 'Ignorer',
            },
            {
              type: 'validate',
              label: 'Donner',
              addPlayerGroupEvent: {
                message: "Don de 4 Gems Magie à Arwen",
              },
            },
          ],
        },
      ],
      information: {
        playerClass: 'Druide',
        competence: 'Énergie céleste',
        objectives: [
          {
            categoryName: 'Couple',
            description: "J'aime mon partenaire de vie tout en ayant le temps de m'accomplir dans mes passions. Nous vivons dans le respect et la confiance avec une belle complicité nous permettant de communiquer aisément nos désirs et besoins. Nous prenons le temps nécessaire pour cultiver notre relation amoureuse en harmonie avec nos vocations respectives. Nous optimisons cette balance tout en réservant du temps pour se ressourcer.",
          },
        ],
      },
    },
    {
      id: 3,
      pseudo: 'Legolas',
      avatar: '/player/avatar/avatar_legolas.jpg',
      level: 50,
      energy: 100,
      weeklyProgression: 100,
      group: null,
      isLeader: false,
      isLoggedUser: false,
      events: [],
      information: {
        playerClass: 'Guerrier',
        competence: 'Vents bénéfiques',
        objectives: [
          {
            categoryName: 'Finance',
            description: "Bénéficier d'une liberté financière me permettant d'entreprendre et soutenir les projets que je désire réaliser sans avoir à solliciter des fonds à d'autres personnes.",
          },
        ],
      },
    },
    {
      id: 4,
      pseudo: 'Gandalf',
      avatar: '/player/avatar/avatar_gandalf.jpg',
      level: 99,
      energy: 65,
      weeklyProgression: 60,
      group: null,
      isLeader: false,
      isLoggedUser: false,
      events: [],
      information: {
        playerClass: 'Mage',
        competence: 'Boule de feu',
        objectives: [
          {
            categoryName: 'Spiritualité',
            description: "Mieux gérer mon nouveau niveau de conscience afin de lâcher prise sur ce qui est moins important tout en étant plus attentif aux messages qui me sont destinés.",
          },
        ],
      },
    },
  ]
}
