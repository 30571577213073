import {createSlice} from "@reduxjs/toolkit";

import {RootState} from "../store";

export interface Player {
  id: number;
  pseudo: string;
  avatar: string;
  level: number;
  energy: number;
  weeklyProgression: number;
  group: {
    name: string,
    avatar: string,
  }|null;
  isLeader: boolean;
  isLoggedUser: boolean;
  events: PlayerEvent[];
  information: PlayerInformation|null;
}

export interface PlayerEvent {
  id: number;
  date: string;
  label: string;
  actions: PlayerEventAction[];
}

export interface PlayerEventAction {
  type: string;
  label: string;
  addPlayerGroupEvent?: PlayerActionAddPlayerGroupEvent;
}

export interface PlayerActionAddPlayerGroupEvent {
  message: string;
}

export interface PlayerInformation {
  playerClass: string;
  competence: string;
  objectives: PlayerObjective[];
}

export interface PlayerObjective {
  categoryName: string;
  description: string;
}

export interface PlayerGroupEvent {
  date: string;
  playerPseudo: string;
  message: string;
}

interface PlayerGroupGroupState {
  players: Player[];
  selectedPlayer: Player | null;
  events: PlayerGroupEvent[];
}

const initialState: PlayerGroupGroupState = {
  players: [],
  selectedPlayer: null,
  events: [],
};

export const PlayerGroupSlice = createSlice({
  name: "player-group",
  initialState,
  reducers: {
    setPlayersToState: (state, action) => {
      state.players = action.payload;
    },
    addPlayerToState: (state, action) => {
      const playerToAdd = action.payload;
      state.players = [playerToAdd, ...state.players];
      // state.players.push(playerToAdd);
    },
    removePlayerToState: (state, action) => {
      const playerToDelete = action.payload;
      state.players = state.players.filter(player => player.id !== playerToDelete.id);
    },
    setSelectedPlayerToState: (state, action) => {
      state.selectedPlayer = action.payload;
    },
    setEventsToState: (state, action) => {
      state.events = action.payload;
    },
    addEventToState: (state, action) => {
      const eventToAdd = action.payload;
      state.events = [...state.events, eventToAdd];
      // state.players.push(playerToAdd);
    },
    removePlayerEventToState: (state, action) => {
      const playerEventData = action.payload;
      const player = playerEventData.player;
      const playerEvent = playerEventData.playerEvent;

      let playersUpdated = state.players
      const playerToUpdate = playersUpdated.find(playerInState => playerInState.id === player.id);

      if (playerToUpdate) {
        // Update player data in players state
        playerToUpdate.events = playerToUpdate.events.filter(playerEventInState => playerEventInState.id !== playerEvent.id);
        state.players = playersUpdated

        // Update player data in selectedPlayer state
        PlayerGroupSlice.caseReducers.setSelectedPlayerToState(state, { type: 'setSelectedPlayerToState', payload: playerToUpdate })
      }
    },
  }
})

export const {
  setPlayersToState,
  setSelectedPlayerToState,
  setEventsToState,
  addEventToState,
  removePlayerEventToState,
} = PlayerGroupSlice.actions;

export const getPlayers = (state: RootState) => state.playerGroup.players;
export const getSelectedPlayer = (state: RootState) => state.playerGroup.selectedPlayer;
export const getEvents = (state: RootState) => state.playerGroup.events;

export default PlayerGroupSlice.reducer;
