import * as React from "react";

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import * as ReactBootstrap from "react-bootstrap";

import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";
import Modal from "../../../BaseComponent/ModalComponent/Modal/Modal";
import PlayerProfileDetails from "../../Player/PlayerProfileDetails/PlayerProfileDetails";

import {
  getCoachPlayerProfileModalPlayer,
  getCoachPlayerProfileModalDetailsIsOpen,
  setCoachPlayerProfileModalPlayer,
  setCoachPlayerProfileModalDetailsIsOpen,
} from "../../../../store/coach/player-profile-modal/CoachPlayerProfileModal.slice";
import {Player, PlayerEvent, PlayerEventAction} from "../../../../store/PlayerGroup/PlayerGroup.slice";

import "./PlayerProfileModal.scss";


import PlayerAvatar from "../../Player/PlayerAvatar/PlayerAvatar";






interface CustomToggleProps {
  children: string,
  eventKey: string
}

function CustomToggle({ children, eventKey }: CustomToggleProps) {
  const decoratedOnClick = ReactBootstrap.useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: 'pink' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}



interface PlayerProfileModalProps {
}

const PlayerProfileModal = ({}: PlayerProfileModalProps) => {
  const dispatch = useAppDispatch();

  const coachPlayer = useAppSelector(getCoachPlayerProfileModalPlayer)
  const isDetailsOpen = useAppSelector(getCoachPlayerProfileModalDetailsIsOpen)

  const show = coachPlayer !== null

  const handleClose = () => {
    dispatch(setCoachPlayerProfileModalPlayer(null))
  }

  const handleToggleDetailsDisplay = () => {
    dispatch(setCoachPlayerProfileModalDetailsIsOpen(!isDetailsOpen))
  }

  // const handlePlayerProfileDetailClick = ReactBootstrap.useAccordionButton("0", () => {
  //     console.log('totally custom!');
  //
  //     isPlayerDetailsDisplayed = !isPlayerDetailsDisplayed;
  // });




  const addEventToGroupHistory = (player: Player, playerEvent: PlayerEvent, playerEventAction: PlayerEventAction) => {

  }




  return (
    <Modal
      className={'player-profile-modal'}
      show={show}
      gradiant={true}
      centered={true}
      scrollable={true}
      size={'xl'}
      onHide={handleClose}
      // backdrop="static"
      // keyboard={false}
    >
      <ReactBootstrap.Modal.Header closeButton>
        <ReactBootstrap.Modal.Title>Profil du joueur</ReactBootstrap.Modal.Title>
      </ReactBootstrap.Modal.Header>
      <ReactBootstrap.Modal.Body>
        {coachPlayer &&
          <div className={'player-profile'}>
              <div className={'player-profile-resume row mb-3'}>
                  <div className={'player-profile-avatar col-4 col-md-2'}>
                      <PlayerAvatar
                        player={coachPlayer.player}
                      />
                  </div>
                  <div className={'player-profile-resume-infos col-8 col-md-6'}>
                      <div>
                          <span className={'player-info-label pe-2'}>Pseudo :</span>
                          <span>{coachPlayer.player.pseudo}</span>
                      </div>
                      <div>
                          <span className={'player-info-label pe-2'}>Nom :</span>
                          <span>{coachPlayer.fullName}</span>
                      </div>
                      <div>
                          <span className={'player-info-label pe-2'}>Semaines Réussies :</span>
                          <span>{coachPlayer.successfulWeeks}</span>
                      </div>
                      <div>
                          <span className={'player-info-label pe-2'}>Progression :</span>
                          <span></span>
                      </div>
                  </div>
                  <div className={'player-profile-actions col-12 col-md-4 align-content-center'}>
                      <Button
                        variant={'primary'}
                        className={'btn-rounded'}
                        icon={isDetailsOpen ? faChevronUp : faChevronDown}
                        onClick={() => handleToggleDetailsDisplay()}
                        aria-controls="player-profile-details"
                        aria-expanded={isDetailsOpen}
                      >
                        {isDetailsOpen ? 'Cacher le profil complet' : 'Voir le profil complet'}
                      </Button>
                  </div>
              </div>
              <div className={'player-profile-player-details'}>
                  <ReactBootstrap.Collapse in={isDetailsOpen}>
                      <div id="player-profile-details">
                          <PlayerProfileDetails
                            player={coachPlayer.player}
                            isLoggedPlayer={false}
                            onClick={(player: Player, playerEvent: PlayerEvent, playerEventAction: PlayerEventAction) => addEventToGroupHistory(player, playerEvent, playerEventAction)}
                          />
                      </div>
                  </ReactBootstrap.Collapse>
              </div>
          </div>
        }
      </ReactBootstrap.Modal.Body>
      {/*<ReactBootstrap.Modal.Footer>*/}
      {/*  Footer*/}
      {/*</ReactBootstrap.Modal.Footer>*/}
    </Modal>
  )
}

export default PlayerProfileModal
